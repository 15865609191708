import { DISCOVER_ITEM_TYPES } from 'src/types/homepage';

export const urls = {
  publishAdsUrl: '/utente/annuncio/nuovo',
  publishedAdsLandingUrl: '/pubblica-annuncio/', //todo: quando sarà online la landing del pubblica rivedere tutte le entry
  mortgageUrl: '/hipotecas/',
  cityGuideUrl: '/mercado-inmobiliario/',
  searchAgenciesUrl: '/agencias-inmobiliarias/',
  agencyUrl: '/agencias-inmobiliarias/',
  whoWeAreUrl: '/info/sobre-nosotros/',
  contactUsUrl: '/info/contacto/',
  internationalLuxuryUrl: '/info/luxuryestate/',
  immoSoftwareUrl: '/info/software-inmobiliarios/',
  publishAdUrl: '/info/indomio-pro/',
  newsUrl: '/news/',
};

export const headerUrls = [
  ...(urls.searchAgenciesUrl
    ? [
        {
          text: 'header_vert_agencies',
          url: urls.searchAgenciesUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_agencies"]
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
  ...(urls.mortgageUrl
    ? [
        {
          text: 'ftr_slim_mortgages',
          url: urls.mortgageUrl,
        },
      ]
    : []), // i18n-extract-keys ["ftr_slim_mortgages"]
];

export const alternateHeaderUrls = [
  ...(urls.searchAgenciesUrl
    ? [
        {
          text: 'header_vert_agencies',
          url: urls.searchAgenciesUrl,
        },
      ]
    : []), // i18n-extract-keys ["header_vert_agencies"]
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
];

export const discoverUrls = [
  {
    title: 'feature_strip_draw_on_map_title',
    text: 'feature_strip_draw_on_map_text',
    action: 'feature_strip_draw_on_map_act',
    image: '',
    url: '#',
    type: DISCOVER_ITEM_TYPES.DRAW_ON_MAP,
  }, // i18n-extract-keys ["feature_strip_draw_on_map_title", "feature_strip_draw_on_map_text", "feature_strip_draw_on_map_act"]
  {
    title: 'feature_strip_mortgage_title',
    text: 'feature_strip_mortgage_text',
    action: 'feature_strip_mortgage_act',
    image: '',
    url: urls.mortgageUrl,
    type: DISCOVER_ITEM_TYPES.MORTGAGE,
  }, // i18n-extract-keys ["feature_strip_mortgage_title", "feature_strip_mortgage_text", "feature_strip_mortgage_act"]
  {
    title: 'feature_strip_publish_title',
    text: 'feature_strip_publish_text',
    action: 'feature_strip_publish_act',
    image: '',
    url: urls.publishedAdsLandingUrl,
    type: DISCOVER_ITEM_TYPES.PUBLISH_AD,
  }, // i18n-extract-keys ["feature_strip_publish_title", "feature_strip_publish_text", "feature_strip_publish_act"]
];

export const alternateDiscoverUrls = [
  {
    title: 'feature_strip_draw_on_map_title',
    text: 'feature_strip_draw_on_map_text',
    action: 'feature_strip_draw_on_map_act',
    image: '',
    url: '#',
    type: DISCOVER_ITEM_TYPES.DRAW_ON_MAP,
  }, // i18n-extract-keys ["feature_strip_draw_on_map_title", "feature_strip_draw_on_map_text", "feature_strip_draw_on_map_act"]
  {
    title: 'feature_strip_building_prices_title',
    text: 'feature_strip_building_prices_text',
    action: 'feature_strip_building_prices_act',
    image: '',
    url: urls.cityGuideUrl,
    type: DISCOVER_ITEM_TYPES.CITYGUIDE,
  }, // i18n-extract-keys ["feature_strip_building_prices_title", "feature_strip_building_prices_text", "feature_strip_building_prices_act"]
  {
    title: 'feature_strip_publish_title',
    text: 'feature_strip_publish_text',
    action: 'feature_strip_publish_act',
    image: '',
    url: urls.publishedAdsLandingUrl,
    type: DISCOVER_ITEM_TYPES.PUBLISH_AD,
  }, // i18n-extract-keys ["feature_strip_publish_title", "feature_strip_publish_text", "feature_strip_publish_act"]
];
