import type { LatLng } from '@pepita-canary/leaflet';

import { countryCode, language, locale } from './constants';

import { isDev, isProd } from 'src/utils/environment';
import { getPlaceSelectionMinZoom } from 'src/utils/mapConfig';

export const mapConfig = {
  attribution: 'Indomio.es',
  language: locale,
  tilesUrlTemplate: 'https://maps.im-cdn.it/tiles/immobiliare/{z}/{x}/{y}.png',
  markerTilesUrl: isProd
    ? 'https://tiler.indomio.es/custom_tiles/points/{z}/{x}/{y}.png'
    : 'https://tiler-staging.indomio.es/custom_tiles/points/{z}/{x}/{y}.png',
  tiles3DRasterUrlTemplate:
    'https://maps.a-cdn.net/tiles/indomio/{z}/{x}/{y}.png?version=2&language=es&scale=2&type=nobuildings',
  tiles3DVectorUrlTemplate:
    'https://maps.a-cdn.net/tiles/indomio/{z}/{x}/{y}.mvt?scale=2',
  polygonServiceHost: isProd ? 'https://s1.indomio.es' : '',
  polygonsServiceVersion: '19',
  polygonNamingServiceHost: '',
  polygonNamingServiceVersion: '1',
  polygonsServiceLanguage: language,
  poiCdnVersion: '1',
  center: { lat: 40.11587, lng: -3.17504 } as LatLng,
  zoom: 7,
  minZoom: 7,
  placeSelectionMinZoom: getPlaceSelectionMinZoom(5, 6, 7),
  placeSelectionMaxZoom: 16,
  drawOnMapMinZoom: 7,
  drawOnMapMaxZoom: 17,
  routeSearch: true,
  geocodingOptions: {
    language: locale,
    host: isDev
      ? 'https://geocoder-api.rete.loc/v1'
      : 'https://geocoder.ekbl.net/v1',
    country: countryCode,
    key: isDev ? 'dev' : 'indomio_es',
  },
};
